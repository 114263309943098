.signup-container {
  max-width: 400px;
  margin: 2rem auto;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}


.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.signup-input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.verification-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.verification-input {
  flex: 1;
  padding: 10px;
  margin: 10px 10px 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.send-verification-button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.send-verification-button:hover {
  background-color: #45a049;
}

.signup-button {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  background-color: #f45b31;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.signup-button:hover {
  background-color:#f45b31;
}

.show-password-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.show-password-checkbox {
  margin-right: 10px;
}

.show-password-label {
  font-size: 16px;
}

.send-verification-button {
  padding: 10px;
  background-color: #eea08a;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.send-verification-button:hover {
  background-color: #eea08a;
}

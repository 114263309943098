/* Import ChatList styles */
@import url('../components/Chat/ChatList.css');

/* Import ChatInput styles */
@import url('../components/Chat/ChatInput.css');

/* Import ChatContent styles */
@import url('../components/Chat/ChatContent.css');

.chat-container {
  display: flex;
  height: 100%;
  background-color: #fcfcfc;
}

.chat-list {
  width: 13%;
  background-color: #fafafa;
  border: 2px solid #ddd;
  overflow-y: auto;
  height: 100dvh;
}

.chat-list-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.main-content {
  width: 87%;
  display: grid;
  border: 1px solid #ddd;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  flex-grow: 1;
  height: 100vh;
}

.itinerary-section {
  grid-column: 1 / 2; /* Spans the first and second columns */
  grid-row: 1 / 4; /* Spans the first and second rows */
  background: #e0e0e0; /* Example background color */
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.itinerary-map {
  grid-column: 2 / 4; /* First column */
  grid-row: 1 / 2; /* Third row */
  background: #edd4d4; /* Example background color */
  display: flex;
  flex-direction: column;
  
}

.chat-conversation {
  grid-column: 2 / 4; /* Second column */
  grid-row: 2 / 4; /* Fourth row */
  background: #d0d0d0; /* Example background color */
  display: flex;
  flex-direction: column;
}


.chat-input-container {
  position: sticky;
  bottom: 0;
  background-color: #fcfcfc;
  padding: 10px;
  border: 1px solid #ddd;
  z-index: 2; /* Ensure it stays above the content */
}


.chat-input {
  display: flex;
  align-items: center;
  padding: 0px 8px;
  border: 1px solid #030303;
  border-radius: 12px;
  box-shadow: 4px 4px 0px #030303;
  background-color: #ffffff;
  color: #7a7a7a;
  font-size: 14px;
  font-family: "DM Sans";
  line-height: 22px;
}

.chat-input input {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #7f6c6c;
  border-radius: 4px;
  font-size: 14px;
}

.chat-input button {
  padding: 8px 16px;
  background-color: #eea08a;
  color: #fafafa;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.itinerary-container {
  position: absolute; /* Position absolutely within chat-conversation */
  top: 0;
  right: 0;
  width: 30%; /* Adjust width as needed */
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1; /* Ensure above chat-content */
  overflow-y: auto;
  height: 100%; /* Ensure it takes full height of its parent */
}

.impression-card-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  width: 100%;
}

.impression-card {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-height: 400px; /* 固定高度 */
  max-width: 200px; /* 固定高度 */
  overflow-y: auto;
  width: 100%; /* 确保容器适应聊天框的宽度 */
  display: block;
}

.impression-card-slider {
  display: flex;
  overflow-x: auto;  /* 横向滚动 */
  scroll-snap-type: x mandatory;
  max-width: 100%;
  height: 200px; /* 固定高度 */
}

.impression-card-slider img {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  scroll-snap-align: start;
  margin-right: 10px; /* 图片之间的间距 */
}

.impression-card h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.impression-card img {
  width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 4px;
}

.impression-card p {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  line-height: 1.5;
}

.impression-card-actions button {
  display: block; /* 确保按钮以块级元素显示 */
  margin: 0.5rem 0;
}

.impression-card-scheduling {
  margin-top: 10px; /* 给调度部分和卡片本身一些间距 */
  display: block;
}

.impression-card-scheduling label,
.impression-card-scheduling button {
    display: inline-block; /* 保证 label 和按钮在同一行 */
    margin-right: 5px;
}

.impression-card-scheduling select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
}

.impression-card-scheduling button {
    padding: 2px 2px;
    background-color: #f45b31;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
}

.impression-card-scheduling button:hover {
    background-color: #0056b3;
}

.status-section {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust as needed */
}

.status {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.status svg {
  margin-right: 10px; /* Adjust spacing between SVG and text */
}

.dropdown {
  position: relative;
}

.toggle-button {
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.toggle-button svg {
  margin-left: 5px; /* Adjust spacing between SVG and text */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.dropdown-menu button {
  background: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  text-align: left;
}
.dropdown-menu button:hover {
  background: #f0f0f0;
}

.tab-header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.tab-content {
  padding: 10px;
  border: 1px solid #ddd;
}


.activity-card-content-container {
  display: flex;
  overflow-x: auto;  /* 横向滚动 */
  scroll-snap-type: x mandatory;
  max-height: 450px; /* 固定高度 */
  max-width: 600px; /* 使容器宽度适应父容器 */
}

.activity-card-container {
  display: flex;
  flex-direction: row;
  max-height: 450px; /* 固定高度 */
  min-width: 400px;
}

.activity-card-wrapper {
  display: flex;
  flex-direction: row; /* 使卡片内容纵向排列 */
  align-items: flex-start; /* 对齐方式可以根据需要调整 */
  margin-bottom: 40px;
  margin-left: 30px;
  min-width: 250px; /* 可以根据需要调整 */
}

.activity-card {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-height: 400px; /* 固定高度 */
  max-width: 200px; /* 固定高度 */
  overflow-y: auto;
  width: 100%; /* 确保容器适应聊天框的宽度 */
  display: block;
}

.activity-card-slider {
  display: flex;
  overflow-x: auto;  /* 横向滚动 */
  scroll-snap-type: x mandatory;
  max-width: 100%;
  height: 200px; /* 固定高度 */
}

.activity-card-slider img {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  scroll-snap-align: start;
  margin-right: 10px; /* 图片之间的间距 */
}

.activity-card h4 {
  margin: 0;
  font-size: 14px;
  color: #333;
}
.activity-card h5 {
  margin: 0;
  font-size: 12px;
  margin-top: 5px;
  color: #333;
}

.activity-card img {
  width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 4px;
}

.activity-card p {
  margin-top: 5px;
  font-size: 11px;
  color: #555;
  line-height: 1.5;
}

.activity-card-actions button {
  display: block; /* 确保按钮以块级元素显示 */
  margin: 0.5rem 0;
}

.impression-card-wrapper.highlight {
  border: 2px solid #007BFF;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
  transform: scale(1.05); /* Slightly enlarge the card */
  transition: transform 0.3s, box-shadow 0.3s; /* Add smooth transition */
}

.impression-card-popup {
  max-width: 300px;
  font-family: Arial, sans-serif;
}

.impression-card-popup h4 {
  margin: 0;
  padding: 0;
}

.impression-card-popup .impression-card-slider img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 1000;
}


.chat-list-container {
  padding: 1px;
  border-right: 1px solid #ccc;
  overflow-y: scroll;
  height: 100hv;
  display: flex;
  flex-direction: column;
}

.new-chat-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  background-color: #eea08a;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.new-chat-button:hover {
  background-color: #0056b3;
}

.chat-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.chat-item.active {
  background-color: #f5dede; /* 高亮背景色 */
  font-weight: bold;
}

.chat-item:hover {
  background-color: #f1b6b6;
}

.text {
  font-size: 0.9em;
  color: gray;
}

.topic {
  font-size: 1.1em;
  font-weight: bold;
}

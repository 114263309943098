.itinerary-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 90px;
  margin-right: 300px;
  width: 1000px;
}

.poi-container {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
}

.poi-container.left {
  margin-left: 500px; /* Adjust as needed for alignment */
}

.poi-container.right {
  margin-right: 500px; /* Adjust as needed for alignment */
}

/* .arrow {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%; 
}

.arrow.left {
  left: calc(50% - 350px); 
}

.arrow.right {
  left: calc(50% + 0px); 
}

.arrow-horizontal {
  width: 250px;
  height: 2px;
  background-color: black;
}

.arrow-vertical {
  width: 2px;
  height: 250px;
  background-color: black;
}

.arrow-tip {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  margin-left: 2px;
} */

.activity-details {
  margin-top: 20px;
}

.activity-details img {
  width: 100%;
  max-height: 200px;
}


.activity-details {
  margin-top: 20px;
}

.activity-details img {
  width: 100%; /* Adjust image width */
  max-height: 200px; /* Adjust max height */
}

.nav-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 5px;
}

.nav-button:hover {
  background-color: #0056b3;
}

.connector-line-container {
  position: absolute;
  top: 0;
  left: 0; /* Adjust positioning based on container width and margins */
  width: 500px; /* Adjust based on layout needs */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connector-line-horizontal {
  width: 200px; /* Adjust based on layout needs */
  height: 1px;
  background-color: #e87070;
  position: absolute;
}

.connector-line-vertical {
  width: 1px;
  height: 200px; /* Adjust height based on container spacing */
  background-color: #e87070;
  position: absolute;
}

.connector-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px; /* Adjust arrow size and shape */
  border-color: #e87070 transparent transparent transparent;
  position: absolute;
  transform: rotate(45deg); /* Rotate arrow to point in the correct direction */
}

.connector-line-horizontal.left {
  left: calc(50% + 250px); /* Adjust for left positioning */
  top: calc(50% + 10px); /* Adjust for vertical positioning */
}

.connector-line-horizontal.right {
  left: calc(50% - 250px); /* Adjust for right positioning */
  top: calc(50% + 10px); /* Adjust for vertical positioning */
}

.connector-line-vertical.left {
  left: calc(50% + 250px); /* Adjust for left positioning */
  top: calc(50% + 10px); /* Adjust for vertical positioning */
}

.connector-line-vertical.right {
  left: calc(10% + 150px); /* Adjust for right positioning */
  top: calc(50% + 100px); /* Adjust for vertical positioning */
}

.connector-arrow.left {
  left: calc(50% + 240px); /* Adjust for left positioning */
  top: calc(50% + 20px); /* Adjust for vertical positioning */
}

.connector-arrow.right {
  left: calc(50% - 260px); /* Adjust for right positioning */
  top: calc(50% + 20px); /* Adjust for vertical positioning */
}
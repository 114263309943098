.group-discuss {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
  }
  
  .group-header {
    background-color: #f8f8f8;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .group-header h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .group-info {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 12px;
    color: #858585;
  }
  
  .chat-area {
    padding: 10px;
    height: 400px; /* Set height for chat area */
    overflow-y: auto;
    background-color: #fff;
    flex: 1; /* Allow it to grow */
  }
  
  .empty-chat {
    text-align: center;
    color: #aaa;
    margin-top: 20px;
  }
  
  .message-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  
  .add-button {
    border: none;
    background-color: #f1f1f1;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  .message-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 10px;
  }
  
  .send-button {
    margin-left: 10px;
    padding: 10px 15px;
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #e64a19;
  }
  
  .agent-status-container {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center;
    margin-left: 10px;
  }
  
  .agent-status-check {
    margin-right: 8px; /* Adjust spacing between label and toggle */
    font-size: 14px; /* Font size for the Agent label */
    color: #333; /* Color for the text */
    margin-bottom: 3px;
  }
  
  .agent-status-check-container {
    cursor: pointer;
    display: block;
    position: relative;
    width: 54px;
    height: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.08);
    background-color: rgba(250, 250, 250, 1);
    border: 1px solid #ff5722;
  }
  
  .agent-status-check-toggle {
    display: block;
    position: absolute;
    top: 50%;
    left: 3px;
    width: calc(50% - 6px);
    height: calc(100% - 6px);
    transform: translate(0%, -50%);
    transition: left 0.3s ease;
    border-radius: 10px;
    background-color: #ff5722;
  }
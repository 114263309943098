.discuss-interface {
    width: 100%;
    display: flex;
    height: 100%;
  }


.member-list {
  width: 16%;
  background-color: #fafafa;
  border: 1px solid #ddd;
  overflow-y: auto;
  height: 100dvh;
}

.discuss-container{
  width: 50%;
  display: flex;
  height: 100vh;
  border: 1px solid #ddd;
  height: 100dvh;
}

.itinerary-collaborate{
    flex: 1;
    display: flex;
    border: 1px solid #ddd;
    height: 100dvh;
  }
  

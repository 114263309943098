/* PlannerPage.css */
.planner-container {
  display: flex;
  height: 100vh;
  flex-direction: column; /* 垂直排列 */
  background-color: #f5f5f5;
}

.planner-filters {
  height: 25%;
  background-color: #e9d9d9;
  padding: 10px;
  border-right: 1px solid #ddd;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap; /* 如果屏幕宽度不够，允许换行 */
  justify-content: space-between; /* 子元素之间的间隔 */
}

.planner-results {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column; /* 垂直排列结果 */
  gap: 20px;
}

.filter-section {
  flex: 1; /* 使每个 filter-section 占据相同的宽度 */
  min-width: 250px; /* 设置一个最小宽度，以防过窄 */
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.filter-heading {
  font-size: 1.2em;
  margin-bottom: 10px;
  color:#f45b31;
  text-align: left;
  margin-left: 100px;
}

.filter-label {
  display: flex;
  align-items: center; /* Align items vertically */
  margin-left: 100px;
  color:#f45b31;
  flex-direction: column;
}

.filter-label-text {
  margin-right: 100px; /* Add margin between label text and input/select */
}

.filter-input {
  padding: 8px;
  border: 1px solid #ccc;
  color: #eea08a;
  border-radius: 4px;
  width: 180px; /* Fixed width for input */
  box-sizing: border-box; /* Include padding and border in width calculation */
  margin-left: auto; /* 右对齐 */
}

.filter-select {
  padding: 8px;
  border: 1px solid #ccc;
  color: #eea08a;
  border-radius: 4px;
  width: 180px; /* Fixed width for select */
  box-sizing: border-box; /* Include padding and border in width calculation */
  margin-left: auto; /* 右对齐 */
}

.plan-item {
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.plan-item img {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  border-radius: 4px;
}

.plan-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.plan-details p {
  margin: 0 0 10px;
}

.plan-details button {
  align-self: flex-start;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

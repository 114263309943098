/* Header.css */
.header {
  background-color: #fff1de;
  display: block;
  box-sizing: border-box;
  border: 0 solid #fff1de;
  justify-content: space-between;
  height: 40px;
  margin: 0;
  padding: 3px;
  border: 0;
  align-items: center;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-left, .nav-right {
  color: #f45b31;
  display: flex;
  align-items: center;
}
.nav-left{
  margin-left: 20px;
}

.nav-right{
  margin-right: 10px;
}

.nav-left .brand {
  color: #f45b31;
  font-size: 24px;
  font-weight: bold;
  margin-right: 140px;
}

.nav-left a {
  margin-right: 80px;
  text-decoration: none;
  color: #f45b31;
}

.nav-right .button {
  padding: 8px 16px;
  margin-left: 15px;
  border: 1px solid #f45b31;
  border-radius: 4px;
  text-decoration: none;
  color: #f45b31;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.nav-right .button:hover {
  background-color:#f45b31;
  color: #fff;
}

.nav-right .button.primary {
  background-color: #f45b31;
  color: #fff;
  border: none;
}

.nav-right .button.primary:hover {
  background-color: #f45b31;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: #eea08a;
  cursor: pointer;
  transition: color 0.3s ease;
}

.modal-close:hover {
  color: #d6755c;
}

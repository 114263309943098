.member-container {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    padding: 2px;
  }

#member-list-title {
    font-size: 26px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: #f45b31;
    font-family:'Courier New', Courier, monospace;
    line-height: 34px;
}

#member-list-pinned-title{
    font-size: 16px;
    margin-bottom: 0px;
    margin-left: 10px;
    color:#858585;
    font-family: "Red Hat Display";
    line-height: 21px;
}

#member-list-all-messages{
    font-size: 16px;
    margin-bottom: 0px;
    margin-left: 10px;
    color:#858585;
    font-family: "Red Hat Display";
    line-height: 21px;
}

.member-list-header {
    display: flex; /* Use flexbox layout */
    justify-content: space-between; /* Space items between the ends */
    align-items: center; /* Center items vertically */
    padding: 1px; /* Optional: Add some padding */
}

#member-list-online,
#member-list-see-all {
    font-size: 13px;
    margin-right: 13px; /* Remove margin left and right */
    margin-left: 10px; /* Remove margin left and right */
    margin-top: 3px;
    margin-bottom: 0px;
    color: #858585;
    font-family: "Roboto";
    line-height: 21px;
}
  
  h2 {
    font-size: 1.2em;
    margin-bottom: 5px;
  }
  
  .online-members {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .member {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 5px;
    position: relative;
    display:flex;
    flex-direction: row;
  }
  
  .member-avatar {
    width: 60px; /* Set avatar size */
    height: 60px;
    border-radius: 50%; /* Make avatar circular */
    object-fit: cover; /* Ensure the image fits nicely */
    border: 2px solid #ccc; /* Add a subtle border */
  }
  
  .pinned-messages,
  .all-messages {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between each message */
    padding: 15px;
    background-color: #f9f9f9; /* Light background for the container */
  }
  
  .message {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff; /* White background for each row */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: background-color 0.2s ease;
  }
  
  .message:hover {
    background-color: #f1f1f1; /* Highlight row on hover */
  }
  
  .member-avatar {
    width: 50px;
    height: 50px;
    border-radius: 8px; /* Slightly rounded corners */
    object-fit: cover; /* Ensure the image fits well */
    margin-right: 10px;
  }
  
  .message-details {
    flex: 1; /* Allow the message details to take up available space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden; /* Prevent overflowing text */
  }
  
  .member-name {
    font-size: 16px;
    font-weight: bold;
    color: #333; /* Dark text for the name */
    margin-bottom: 4px;
    white-space: nowrap; /* Prevent name from wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis for long names */
  }
  
  .message-content {
    font-size: 14px;
    color: #666; /* Slightly lighter color for message previews */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate long messages */
  }
  
  .message-meta {
    text-align: right;
    font-size: 12px;
    color: #999; /* Subtle color for metadata */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  
  .message-time {
    margin-bottom: 5px;
  }
  
  .mute-icon {
    font-size: 14px;
    color: #ccc; /* Grey color for muted indicator */
  }

  
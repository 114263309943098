.home-container {
    padding: 0px;
    border: 0px;
    margin: 0px;
  }
  
  .home-container h1 {
    font-size: 2em;
    margin-bottom: 0px;
  }

  #bg {
    width: 100%;
    height: 1000px;
    background-image: url("../../public/IMG_3859.JPG");
    background-size: cover; /* 让图像充满整个元素 */
  }

  #p1 {
    color: #f45b31;
    font-size: 68px;
    float: left;
    position: absolute;
    left: 100px;
    top: 300px;
  }

  #p2 {
    color: #ebda59;
    font-size: 40px;
    float: left;
    left: 110px;
    top: 400px;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
    animation: typing 4s steps(40, end), blink-caret 0.75s step-end infinite;
  }
  #p2.repeat {
    animation: typing 4s steps(30, end) infinite, blink .75s step-end infinite;
  }
  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }

  @keyframes blink {
    50% { border-color: transparent; }
  }

#functions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#HW {
  text-align: center;
  position: relative;
  font-size: 45px;
}

.feature {
  width: 100%;
  max-width: 1000px;
  height: 600px;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 150px;
}

.feature h2 {
  font-size: 32px;
  margin: 10px 0;
}

.feature p {
  font-size: 18px;
  margin: 10px 0;
}

.feature-image {
  width: 100%;
  max-width: 440px;
  height: 100%;
  max-height: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10px;
}

/* Individual background images */
#HW-Chat-Assistant-image {
  background-image: url("../../public/step1.gif");
}

#HW-Match-Assistant-image {
  background-image: url("../../public/step2.gif");
}

#HW-Pitfall-Assistant-image {
  background-image: url("../../public/step3.gif");
}

#HW-Explorer-Assistant-image {
  background-image: url("../../public/step4.gif");
}

.footer-container {
  background-color: rgb(241, 239, 239);
  box-sizing: border-box;
  width: 100%;
  height: 400px;
  top: 280px;
  position: relative;
}

.footer-content {
  padding: 6rem;
}

.footer-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2.5rem;
  display: grid;
}

.footer-grid-col1{
  grid-column: span 2 / span 2;
}

li {
  display: list-item;
  unicode-bidi: isolate;
}

.footer-grid-col2-link {
  list-style: none;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
  text-align: right;
  margin: 0;
  padding: 0;
  width: 190px
}

.footer-grid-col3-link {
  list-style: none;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
  text-align: right;
  margin: 0;
  padding: 0;
  width: 100px
}

.h2-step1, .p-step1, .h2-step3, .p-step3 {
  text-align: left; /* Left-align Step1 and Step3 */
  align-self: flex-start;
}

.h2-step2, .p-step2, .h2-step4, .p-step4 {
  text-align: right; /* Right-align Step2 and Step4 */
  align-self: flex-end;
}